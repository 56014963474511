const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');
const { soliditySha3 } = require('web3-utils');

class Merkle {
  constructor() {

    // We'll need to hardcode these whitelists for the web server to
    // instantiate the Merkle Trees onto the frontend.

    // The issue is that require('fs') does not exist on the browser, 
    // so the CSV files cannot be read on the frontend because Node.js
    // does not exist in the browser.
    this.whitelistAddresses = [
      [
        ['0xC1bC29ef5Cff0C1958DF3426Ebc765f0A3230678', 2],
        ['0x3bbeF7Fd12FB92c00355D5208229E425E29fA91F', 2],
        ['0xD281c8625f18B94A0C39E88Ba0F3821DAb945647', 2],
        ['0x24fda918c1cC2947486FB1529D1b1E863B1BCCF7', 2],
        ['0x71966ac090F6cc0aE6b8c1C24F5bC4D78c1D3982', 2],
        ['0x20E56f5E3d4B100C2C1e73873F59B7B5A84b368c', 2],
        ['0x1aaCB4100fb438d4331D8835AC915e0517Ebc860', 2],
        ['0x768DEB379c56D68BdE69eF19aC0708729ca3fD99', 2],
        ['0x421dC0e582382A8CADcCc36c724c3d60fcF39040', 2],
        ['0x769d7d1A56F0bcC95Ac1604781CFB74C088776A6', 2],
        ['0x62c9E9a412000b5f82208868217F4Ea1E76D5408', 2],
        ['0xE0E5E0389D1A9DcFBc4BEc918C7b49cfe8C1b4C2', 2],
        ['0xe098fB11a41C6d27467262543A082911714E4644', 2],
        ['0x93EBb8C346226FB2C959EFE423134db848cD59bf', 2],
        ['0x45b3b6F94a8c8c94c953d4a2109011EED8957A69', 2],
        ['0x90b52b6DE527c583E5aE06840174F51E2Ac4C106', 2],
        ['0x419044327EeB77dDBFB01aAcf381Fe29ed330ea9', 2],
        ['0xF3CF50a63F48819EdC106f33bDFC68f6591Fc0E8', 2],
        ['0xBb7C0dbA308916Ebc3D78AC2AdC53879881C2cC8', 2],
        ['0xd317d2233A19EC8f40675285045f85f6BA89a2e2', 2],
        ['0xBDC0F5b3528F1f04cf824Ba2e0bD9ecb57EaA7Be', 2],
        ['0x78fd06A971d8bd1CcF3BA2E16CD2D5EA451933E2', 2],
        ['0x030B37484b57c0C284D2D825626812fcCb3A8747', 2],
        ['0x8C84BA68b7E9Ad4D901E8254C521f1de7f5d2101', 2],
        ['0x6701E46aBaEf50E0cFC3A4a879cc1107b59899F3', 2],
        ['0xFBBF00F6c2a8B399728bC7E2Dd6eEE684C791745', 2],
        ['0x3bB1Db0AC38028235fa9364C078c83e3c9b9EA79', 2],
        ['0xd7900e1Fee30e70EfDd5D377a7cEC457Ea81142B', 2],
        ['0x671123d5D99041348Efc4fe93a63F420D234AA44', 2],
        ['0xF860eD6D8EEF0E7Fbe64f99FD7FDe2CBE3e30C68', 2],
        ['0x67163cA66f307ec85c78dCd94e3F59373c108D61', 2],
        ['0xF860eD6D8EEF0E7Fbe64f99FD7FDe2CBE3e30C68', 1],
        ['0xaBFd205eeB702D681d396E8bEAD74f62417BF5c4', 2],
        ['0x8143AaD694567424162A949c1580c91D03437858', 1],
        ['0x8143AaD694567424162A949c1580c91D03437858', 2],
        ['0xF35DfbE80dff45aF1b79B8c287240d8DcF82fDcE', 1],
        ['0xD73d30C3b773384862D0b46D443DbeDaeCE6DEeB', 1],
        ['0xd7900e1Fee30e70EfDd5D377a7cEC457Ea81142B', 1],
        ['0x62c9E9a412000b5f82208868217F4Ea1E76D5408', 1],
        ['0xef6130fFd5257575170C78Ba0dCE200301059217', 1],
        ['0xF7d1ce6BEe46258F1E6A3d4bb33144D4624BE2b2', 1],
        ['0xf4E1Ae50295b55FB24027C0c2f4cBC4C5D1b9555', 1],
        ['0x470ca68b32f362330Fb54F93E54Ac1Cc288e16EE', 1],
        ['0x4052B6DaF98E8c135ED94852F87905fEBbFF13a7', 1],
        ['0x2430D31dde3fE7333083808c983e9d659c5414F2', 1],
        ['0xE884962551561560BE746C9f81f90a60176eBA00', 1],
        ['0x24fda918c1cC2947486FB1529D1b1E863B1BCCF7', 1],
        ['0x269bBa29557F7ACFe8e111a7A2f30688c08851d9', 1],
        ['0x9d9d8ae52c032aDA6d672d8b9f45D93E97D73f39', 1],
        ['0x1Ae61e5CCB59f370EBAE8b4A6426cf1FeF969f4F', 1],
        ['0x0349b10a1A14384472e0b77c00355F4C434218c1', 1],
        ['0x96d57cb6e2D07484170e58f4c00A9535380970F5', 1],
        ['0x73B2E83fA1EE3057053350e830Ecc3b535672B62', 1],
        ['0x4Ab3E9e35fBC6208Ae912f363E46378E01ee7E92', 1],
        ['0xca822d36D06D2ad05B3ed5381d016ad721Df97e3', 1],
        ['0x1c2d400da1049c4b31F2De95F3401DB3594E2925', 1],
        ['0xb5750a89f5b23ad981f0d07f2272b72b78a44692', 1],
        ['0x0e20CD601F6e822aC7E1a2736F9E5788A6553d2F', 1],
        ['0x132cbbEB9EEA79Af48E458a5a745F65b1061f674', 1],
        ['0x71966ac090F6cc0aE6b8c1C24F5bC4D78c1D3982', 1],
        ['0x952390fC20eCF52352C8e7Da74fab898c2717C53', 1],
        ['0x285a0781049F5D108daccb95BEE46EF3c861c26c', 1],
        ['0xD91F62B8099005d2b9c9E7F07c2e4421E5312365', 1],
        ['0x7499DE64B726F00530eF096b2Aed9b8e05891eA0', 1],
        ['0x3bbeF7Fd12FB92c00355D5208229E425E29fA91F', 1],
        ['0x5ffd84AA012cd31325CC8bDef7dD97770Edd5677', 1],
        ['0x97241d07d61874910AcE7459eAEE04bB859Ba469', 1],
        ['0xE8d8B73CCC85dEd891ad41893Ebbb0d684350E04', 1],
        ['0x42704638CEeCA4BAa514c03EFB17Aa9Dd4f555a2', 1],
        ['0xf9807FEB302B6c7E8c51812145B9a24c917DEf30', 1],
        ['0x5E78E96187789AEfc38eAF1790A40a87eB0B641f', 1],
        ['0xA051245C4636d41CA5eE2f29EC7F26D12e9dbc52', 1],
        ['0xF6f68F470b79843c37eb087E0C7351c4b6fdd9f7', 1],
        ['0x7f73D55f65021c63A53d3a27F43533af4C196593', 1],
        ['0x11E32B8fe315620B88cc28F3dBbFE8B94dfeF50F', 1],
        ['0xc6579463baB5BCB90a9635bef91CcAa78fFFD7b1', 1],
        ['0x24a940522b701b138aB40A61fCCC2b8630d00725', 1],
        ['0x5da356e1eC6d2D70e1Fa7BA4B062563c281E20f3', 1],
        ['0x07A4c60c6CcDf6Ff3EA28Bbe8D0C21D6D1260ceD', 1],
        ['0x91bc879b665A9C024745a99f9cc4A40AC8d5963C', 1],
        ['0x27BE2802bF358a005fdf64Ac0BacE7c7bB0B8727', 1],
        ['0x7a17E013C3DcaaCe957a3E15de66943866E40860', 1],
        ['0x4a9025B24B4936Fdd385d0516d3A73c6Dc51E45e', 1],
        ['0xcA4f1c5a8d87977128AcbF3F4B06B05c30508c18', 1],
        ['0x2b23E4E377f2494955089040c1d56A116cF55956', 1],
        ['0x1420c3d6dFE514060Ce85b2Ecbb192Ae8511bD9f', 1],
        ['0x9a54cBe6B166Cb27B9C7B474D8B961b67C21643a', 1],
        ['0xe5dF725FdB82D9CECFc84B7bB99Ca7B4990f5DCC', 1],
        ['0x418d0198e68CF2D39f9f3f9AD7154c3cF1433113', 1],
        ['0xb4ABd42E60D76cc30092c625976cbdbb5F255eAB', 1],
        ['0x68A80F974C1cd147119Aa685B241b75e93De464b', 1],
        ['0x305BEC3BBf6Cbac839D8Cd3966eDd2F8549fEf3B', 1],
        ['0xFa34632354534aEeDDEc75D46199b0a8AF0222E5', 1],
        ['0x28A8e02b26F00C0a77aDF536c51DEa10c58CA1a8', 1],
        ['0x0844E174a1c254cC0af19DC62828D9fAaE91fE97', 1],
        ['0x030B37484b57c0C284D2D825626812fcCb3A8747', 1],
        ['0x421dC0e582382A8CADcCc36c724c3d60fcF39040', 1],
        ['0xe5A603ec27B95a9b370017869824473259322AA1', 1],
        ['0x87D8E0eb002E0A227Bb495542D232b7370588363', 1],
        ['0x527c973eC0ceb832C259ed8d1102e2503D0194A2', 1],
        ['0x0a90b83884870046b73441af03b76c35c1d21763', 1],
        ['0x97Ccb2DB265fA5EBA774cB609A4F7A625c286856', 1],
        ['0x6D67527C0e20193d507D59982be282d6248fEAEe', 1],
        ['0x2F72E004EC9423AcAA1FbD6D17Ff2eCD2e6F7eB8', 1],
        ['0xAF920881bf968e789D6349896DA6bb0152d06cAa', 1],
        ['0xEdae7ECAdDbF0a7d3ed2d02F13D35be790865D60', 1],
        ['0x20f147Ec6F93424aF59702a416AF04c0a2c1F43D', 1],
        ['0x10a108b045D0c97Ae7D69cBAFb4d5B97c22bA63c', 1],
        ['0x375Dae8e3F7c60AdfFa7db18590Dcdf6F0de2223', 1],
        ['0xbcC4aD525f47232C5D4A7Ab3E510CcB30F06D5A9', 1],
        ['0x176c5c15865E399Df9316176B468c782F013cef3', 1],
        ['0xa741EA8C69Bf88Cdd826c70583B932d865617a6e', 1],
        ['0xa787982969073d3B4Fa4120b7D9c505FAB3517D4', 1],
        ['0x9D0dA7fA00BEd2CFA6F1dEc9D9C16F075A589673', 1],
        ['0x1F29cba44478Bd2df384e4b21B39e6fB92F350fa', 1],
        ['0x30ABD5639d60e6469BAD1D72B2cCDC6FEefFD305', 1],
        ['0xe05093851bF1C0d8F072EabfB8f6DbD559e56FDC', 1],
        ['0x2196e621492517912153bE482C436a36D96d1373', 1],
        ['0xf8deE612132d1C181cA8B03B1b8a38AdE818D75d', 1],
        ['0xA5d66a1F57c08389C8C6832efeE8A197d40b165a', 1],
        ['0x9Daa645B87712Deb34722A0A96EF87A94b768C24', 1],
        ['0x2B8Cf0eE5573334723a84eD85d87c1D9DFD58F41', 1],
        ['0xe2e1e7aFbd000Ab3158Cce1a7C57b182847A64d7', 1],
        ['0xF3CF50a63F48819EdC106f33bDFC68f6591Fc0E8', 1],
        ['0x71784A8B738a340C433c48ccE913c1f633869830', 1],
        ['0x14D16805907B4E7441E8E7D3FdE1A629E63917D9', 1],
        ['0x23B5162afD0c5cf2b85Ba33531f2DE160cDA800A', 1],
        ['0x20E56f5E3d4B100C2C1e73873F59B7B5A84b368c', 1],
        ['0x93EBb8C346226FB2C959EFE423134db848cD59bf', 1],
        ['0x8EBb2619d15FcCa5acd85209D3FF03B0F2CCE39f', 1],
        ['0x91860FF0A153870C4e55493b828C75AC8B157Ddc', 1],
        ['0x01b01922b50269e978801Efafa793Dd93d653365', 1],
        ['0x2DcabEc2934D043D74e5234a5BAD2924C07208eD', 1],
        ['0xFa2785CA21A99866E9dc597D6401FC94F1Ec7B02', 1],
        ['0x3767c05851f00Cd6A5fC6247BFcCB45b9e0432d4', 1],
        ['0x7A3aC1282d2Acd589FF553bFB5B6Ee4d0Af2Ba8E', 1],
        ['0x5E8bb171EfB4EF1DA79479C794d5B8ec550233f1', 1],
        ['0x60935d2176038472072a0FF7C71e4125FD8032Aa', 1],
        ['0x40421E27480caf8FcbB7cDd6424C4A9b4AC02f73', 1],
        ['0x26ec7f6E8879d8Fc3C6347c64670fC9f938E3c4a', 1],
        ['0x93A52b13AE0d26CE0aB9c1d5bD77f78CcDcc8497', 1],
        ['0x9bE394Eb181fccb33e624FDfFE9EB0Dba830f4c5', 1],
        ['0x88906af7616F88f610d1E479325a5c5f93bA1e2C', 1],
        ['0xD9995c31e55cc4fad781c5f4Cb206D9c02FD5743', 1],
        ['0x1B18a7b058EedAC1F55C74422cc3d5A694D5D023', 1],
        ['0x688d0CeF9d2Ad572E2b889eA595a042a1821e5Ff', 1],
        ['0xc8d43b0ebe1df62026aa6dd36bd62449e6b0efed', 1],
        ['0xBb7C0dbA308916Ebc3D78AC2AdC53879881C2cC8', 1],
        ['0x85Eb459351c8b957f0FB4fC59cD719aAA52cf486', 1],
        ['0x6488004798664CfAF82F50695De93ED3257F8147', 1],
        ['0x91C1C08553758d893Fc82dfD69382CA38fdfab23', 1],
        ['0xc48A1b7aaeC73205363dC368241b0d5AeafC8A89', 1],
        ['0x91395B97F65241296F78855A0Bef9a4bcCC52585', 1],
        ['0x8F9752a70D362Fe3E8d4bDD8961462BfE65A744C', 1],
        ['0x4366f72e2D240aa4fD4A21C2863B4E7890109d31', 1],
        ['0x78fd06A971d8bd1CcF3BA2E16CD2D5EA451933E2', 1],
        ['0x95e2356c1f7e273FF0B867b304A1b443170570fd', 1],
        ['0xFa19808C6b0257F3F3844697752353E470fA903C', 1],
        ['0x7F14804fb5c65c2B625c7E61e0326fE2D9874044', 1],
        ['0xd960b569d1552252DDd0701becc6975bF12da954', 1],
        ['0x0B20B9Bd6eA6F9d75c3eb676E703056e1b7538e3', 1],
        ['0xeE5c8665a4e647fD91c92913222632e078ef9Dc1', 1],
        ['0x6701E46aBaEf50E0cFC3A4a879cc1107b59899F3', 1],
        ['0xcF76AD18D25d0eD3F7Cb88691883D6C174C13740', 1],
        ['0x0cc68cE9A15397FdEBB6425d52F795C7919430B7', 1],
        ['0xA0e3c16CE2813f650cCDA46c7D0Fd64Bfc8C8291', 1],
        ['0xCBFC3B068F2027E8852d9704A51Bc7E4A73F3e62', 1],
        ['0xf1D891a4ee23bd69fd7302817B171163E5c8Ae4c', 1],
        ['0xe086f19aD9B71780483886Bf817b4CB8C5B4002e', 1],
        ['0xD39dD7Fcc4D192c6a422Ce9d63558432D5Ee3082', 1],
        ['0x8b47dB971E5481a18E4ce73bF8888cF9950c6Ccd', 1],
        ['0xFA4B47D26a8Ec5B50c2d118c1d20058E7210512C', 1],
        ['0xc942496E98B9f8DE243AF4226B8a9398A141913C', 1],
        ['0x74Be98Ce482dF6f59B3c01FCfdCF9b9EddBB4A98', 1],
        ['0xd4D992F9509Efe9b3ca1b7aCFaAB384ebB99e0cd', 1],
        ['0xDb1851aF8Bddbc2275A3947e77542d1280373C54', 1],
        ['0x27cc5B44FC727f216cdb8a0844Fa433a6117DB87', 1],
        ['0x3492606E68208B40C96f2F5771EcCF6e49239241', 1],
        ['0x716Dff1a69fE389E68F144ad7d241b5F077c3d96', 1],
        ['0xf48182be3E620429aAb6F64B514AE43295551b8F', 1],
        ['0xe293a27c51Fd8798Fe503779A7eb9200e3368a27', 1],
        ['0xAF5A6020fD13050Fea0275136aBE0625f135B553', 1],
        ['0x38459f5Bb4D90c94D469b09773ADb725627B1576', 1],
        ['0x6d1895ed5a76E6f189ba125D7571d5729b484210', 1],
        ['0x7796D7E2F04b2854cB32F52C6014bEe89fD93C18', 1],
        ['0x3F7b37E709614129CaE1cB379c0BE52Bb96Ee290', 1],
        ['0x0704bD396D425f2e794e9b921bff16c020e4a95F', 1],
        ['0xDe29C9E6F447e9159bD4c1D0E96bF0626A291c1E', 1],
        ['0xdE393b2AC88f082A8035498720CE88d757E9A2C4', 1],
        ['0x7708A0C3F7Af0e099E3263f459413845fD120E44', 1],
        ['0xE849450c544D80edffc4493a96Ffc700aa9ADA42', 1],
        ['0xb644a16A72880438D40Ad33b4f7f12dC758B646A', 1],
        ['0xa625b98bd8e6De449BC33fb5Cc2437A015ae3C01', 1],
        ['0x216b39058041B57Ef22Cc1314f684c137c7f4216', 1],
        ['0x151b8c6E66BA9Da24720BD95fb5C1420C0fa6236', 1],
        ['0x2A28d0C642c2719cA78f9C7334538c52d5a3cE9d', 1],
        ['0xd8D058366A7624AABdf4e67c058F8B1286B6e34A', 1],
        ['0x1aaCB4100fb438d4331D8835AC915e0517Ebc860', 1],
        ['0xC2d1b8B81a2F0977dE6552cedF1007e328119077', 1],
        ['0x768DEB379c56D68BdE69eF19aC0708729ca3fD99', 1],
        ['0xf22A24b27217D63cA83AAf08c5c4BFf19BEa730D', 1],
        ['0x1035F753d18F2e0A928460136F3b8308645E3946', 1],
        ['0xda2278f14858bc5EA69014718A020e34e97cd222', 1],
        ['0x2aBf7288dB62005919934D6665fbc14B8E00a408', 1],
        ['0x502ab464BdF9F3FD197E9E7CD388c78Aa90B8378', 1],
        ['0x46d36f9446e40aB786c7bd005447A6a9Ca2a632A', 1],
        ['0x8a622Bc901de1fa2384d42FFA79606e446eD788F', 1],
        ['0xedb4Ec2E401B984264b4bf8B7DD62a9ec11Ee37A', 1],
        ['0xEaCE06c09a26AA0B44FDD6Bcc730DB08E4711210', 1],
        ['0x575D332F0cB8E401DbD22067FB78D2F4B911C445', 1],
        ['0x6f9417D10F04a935fD9D6173aBc8D662dbCC3a8a', 1],
        ['0x7079F303f6eB59E26c3d2cb5D0dd7bdbB3217189', 1],
        ['0x1Ebafec2fEA12981e2c12cDef5D8f09C7E15EDaE', 1],
        ['0x7Ed43F1B7bdEaD1a7A5b723276dF3Be19E73e4A8', 1],
        ['0xa3Af3F704F0fDf421fC4197A4aEeabbE7Dd9c757', 1],
        ['0x192de53DE8e16ac09e0654B58F5E9d9ceBcf7ec6', 1],
        ['0x9b85df2D9a4BA31c33381a509d26ff842456f098', 1],
        ['0xBdE1b08071421AAB08BbB3133097A589891c25F5', 1],
        ['0x5CccBcC0cd0BB4371C0Fc69B31f67d40bD390312', 1],
        ['0xe098fB11a41C6d27467262543A082911714E4644', 1],
        ['0xE34CEa6bA44080Fb5C2307db6c0b375Edb5E6e54', 1],
        ['0xEBEb72C2bBFACb9DF830327D12366D6eec079979', 1],
        ['0xc7707AEc15A0e66A6468EF7C9cE05F15b60A930E', 1],
        ['0x6d79af750b839414F63966288e40c8E7fBA3F0CF', 1],
        ['0x3aBBb2D222d80D31f7bd990635516066EEA3B21F', 1],
        ['0x122a888559146D1eEa429589cF9e7fF82Bd13201', 1],
        ['0x8270264287E415cbc3DD8dE12c72416142E3A5fa', 1],
        ['0x5782beC75B4AfdcaE682aEb717872107a87c6f74', 1],
        ['0xB43F16ae4ec0087CF0F620a601844F86c75B2f4C', 1],
        ['0x13555d65CDA6b810552E8C89532eB5174bAdC4BD', 1],
        ['0xcE7C679965A06aB8C9F6526262B0C29e15727d61', 1],
        ['0xA1E156aD3D4e5a42820E0471A6A41Fe5A6259b39', 1],
        ['0xe4DD6E3F7b1644bC8d5eA2a2494d81A6606f7f7F', 1],
        ['0x77cf672e5A9525D24265AE70111bd995FE73bc82', 1],
        ['0xcE8C9B0e0f74A1D8fc90BDFeE4c135Dd11A0d837', 1],
        ['0x644399a6F6bAB3dab700A084bD6020D004300f3C', 1],
        ['0xE9F26C62478735231AcD8acfd8b4A7F5fe6E6A5C', 1],
        ['0xd386D2c20e16e978157336237eC0E221ed51711b', 1],
        ['0xCD1e799976D5F41f8EE47f776e80157521Eec524', 1],
        ['0x889355521Daa41917E01ea9c4Ff78A33D380BF21', 1],
        ['0x9f68E48ea2D9e5624B86aa91624f3766238f840E', 1],
        ['0x19694104253Ee9B75DC693DAAB8e4B2C8EEB5DCb', 1],
        ['0x9EB497B96D3772bbb27bA089a54F58326d6AfD30', 1],
        ['0xE827b412F3cbC15E62ac24BA3d2C10E214c44E0d', 1],
        ['0x72D866C3b9b1a40cc1e4E931D09f10deB1A5A0Dc', 1],
        ['0x671e7f7E4cE41BfF4Ade869AFa0DA8E5a436ce50', 1],
        ['0xe36338c1B2c10969A3E4Ee93C11a45D7c1DB3352', 1],
        ['0xdf779C6d82fdd6FB529E73e993e119E3c8d1f8cF', 1],
        ['0xEbA91Bf43587286d8CC50C5BBce4304e477f8654', 1],
        ['0x7Fcc63B2d7dA40145d1a2aFd852c708cA8c3e996', 1],
        ['0x04A2a0d00529260913b46ebc87545b1a9DC7c322', 1],
        ['0x143BE291F6d77a7aC59f4ba2e78dfc18Ecc14F01', 1],
        ['0xC2BBfA869877B8Bf2AEbaC55f3881BAb21a21542', 1],
        ['0x80F88522bDDCD3636A8146aC4A33ba5E7f15845f', 1],
        ['0xAC5901350640baefb46f263fF5e98232177aD1CE', 1],
        ['0xE042b1E03e9BCb70aa136b14cEd072db19B395c7', 1],
        ['0xF2043513d5bD0def58f9CA635ABf1CA8dc68B7ac', 1],
        ['0x4cD7De08ae9717C9B88fd19CCEc39A99d03373b4', 1],
        ['0xd2c4659c75b21796194887d604E01627fDc01032', 1],
        ['0x1a6c4113d6b0ed5549BfC2D6caa0c01C6019598a', 1],
        ['0x6d3bE7D84B922822c2FEe9D1D17EFBE44d1DC5FE', 1],
        ['0x28F1971Ca451c8f58C9A1474C8316ab089D7F341', 1],
        ['0xeCd5E77aeBe58ee4976936C71fdaa17a58ce837C', 1],
        ['0xe3BeeA63eE3083Bd71B095dBdA50179264b9CB1e', 1],
        ['0xBDC0F5b3528F1f04cf824Ba2e0bD9ecb57EaA7Be', 1],
        ['0xE1a0760D7ce96fB61A6E7D3B74279fFD0555e0A3', 1],
        ['0xd2EC1af2104b9F410BB5955DAd0b8c024016d03a', 1],
        ['0x793a7fEfbBf9b971E71594C3618f305E247cE0e5', 1],
        ['0xDCCC159C77c39B9f2bF79BaC172d89b9a0c9Ad51', 1],
        ['0x43c328A222C487f3a0C4DB6bA90eaEC59cFD579F', 1],
        ['0xE0E5E0389D1A9DcFBc4BEc918C7b49cfe8C1b4C2', 1],
        ['0xAA92E9c25f16137C3B0d12853d8A832ce8A149c1', 1],
        ['0xC7cFa5e50c1a725187C20Ed94C81639EbcccAF67', 1],
        ['0x11EB5Da195343953Cc930a725b33A3D76fb52e4d', 1],
        ['0xC075Ae29E86bC9a2a7047677F09EA529685c4361', 1],
        ['0x86562A3ab7d164f4699FEFd541A0aa0701CA3f4f', 1],
        ['0xe7a3C5101380547b90785dCEE43624ca05A1E354', 1],
        ['0xBfe2205b2b60b2Dec0e5B6CB086792301d409d42', 1],
        ['0x18277669C9843156c3910549672000a5102dFEbD', 1],
        ['0x400fF67bd2F0720b91c040032880f9714485c3D9', 1],
        ['0x289526De2B974Ca307B7D383D2C091E7Cf03c92A', 1],
        ['0x93c60606CB51cF123731744FA70b5da1828dCc08', 1],
        ['0xD481281C8c12D477d6A0f4A6E24b6F4d074Ed989', 1],
        ['0xbC488A070d9Eb24706F5B45a7c235e5d13994de5', 1],
        ['0xe795CDD72FF01EB5e0B407b9F1FcBCA7740234fb', 1],
        ['0x63D92586AD8081A0A65a0d7b5b5fB1B25A386fBA', 1],
        ['0x15d8Ad7b177a274c3787D640a3CcD60eE5886817', 1],
        ['0x5636645ECaa891bbEd81a3C0333a18EBcd34257a', 1],
        ['0x2ED534E8353ac519b669953eaaCA29C5F15624E0', 1],
        ['0xf592dCf7b4b97A3EF4b35B25dEc9525a4A65cA68', 1],
        ['0x50173504846B81C015317537D96f9A88b223b266', 1],
        ['0x4bB4E78d5391a130d9AfC7De014cFF3c4724a30e', 1],
        ['0x399201235Bc455348668AF152D76B9fa6e03e979', 1],
        ['0x5b59A00b6B9C210DB0eB7EE32Ab2C406a876E73f', 1],
        ['0x4A889a12c7C5AECcFE956FE06d6286Ef43BBd445', 1],
        ['0xd317d2233A19EC8f40675285045f85f6BA89a2e2', 1],
        ['0x082c8a4c66915Ec53bE618912811503eaF2576b6', 1],
        ['0x4267cf6d5530C713a9C8650C219a6d82889AF45C', 1],
        ['0xD281c8625f18B94A0C39E88Ba0F3821DAb945647', 1],
        ['0xB050143eb5692e55B43012a6a619A6559b02F950', 1],
        ['0x8Eeb007dc2C1EDa70B58D42469975EbF69be696d', 1],
        ['0x366e9a0428599925fF23568D5b66A1353e704615', 1],
        ['0x12A416f943D6503D3EBcc9289378763133652Ce1', 1],
        ['0x946CCfB4D7930E0EA47F2886B8F21EC09565b1cb', 1],
        ['0x90b52b6DE527c583E5aE06840174F51E2Ac4C106', 1],
        ['0xaeD4236620813915681935f9bf23159142FC77c7', 1],
        ['0x1a1A7067866CA7F023c294cD2CAE6f38509D9aC2', 1],
        ['0xbc9AeA349912C6D0f6767a350C172696447fC9Ee', 1],
        ['0xE75cF02FDc1eF65Ab97f0785338435Aa60789A1C', 1],
        ['0x1224bCE2E52F801FF95766fD3A772A7FA155d225', 1],
        ['0x631FD3595e3Af85F9B57BB98DB6f0bD3c24Fe4d2', 1],
        ['0x1B3eb06E7e61C865FEdFDd466e6cA1d6948a4F67', 1],
        ['0x8CFC859bb43Cd7C121E789511ED2B096F324d278', 1],
        ['0xc0C05b3510fABA4233Ec90f14683fAb9384C68ff', 1],
        ['0x55200e4994E515f9d5cD0Cd6fcd652ae839795Dd', 1],
        ['0x17e74Fc9709AD593FC1b18fe16b78762db1BeBce', 1],
        ['0xb88874eC3d5B04eB451D45B93c746b4404dC5461', 1],
        ['0x7088734898d4b26E3d70c0368dDa37430e635aF1', 1],
        ['0xCbA9FCB7c085dF68caf6FE51D4De53DB59FDd283', 1],
        ['0x228A5802A57a6F9CDc9B2822f944125b28226aD9', 1],
        ['0xd4F8DfD1cDBa76e9ac6b3b31Ef3C6C6c3D1ea1d0', 1],
        ['0xfD924164C8736AB833489BAF85d906942681c77B', 1],
        ['0xc6781aE3aAe8d603D9f39716b71f202D5e7940b6', 1],
        ['0x72d64c3C0d141eFf964B99247e8aD2F0C171EacC', 1],
        ['0x09C3Ae5ff4244196aDd8EbC4402B4258514AAf13', 1],
        ['0x496A4c737318C9efbE68a70AFb1e427A52945E46', 1],
        ['0xC648EDD24396da526712BB5C630Bcdb838d60Da7', 1],
        ['0x95791021b248dded2028c9c85aD98E32bAea18c1', 1],
        ['0xF1FD037Af912B7151479E26245bA1288634D7431', 1],
        ['0xf840e4A8B80cD63c41B89eBe36514215D44DD3de', 1],
        ['0xb1883eC4eE42EFd5F660a8264D6b6D976C567206', 1],
        ['0x1412028402fB587ef4F04A99451225D266588782', 1],
        ['0x9fe6a9a1B0F0D68EB4C9D01c0f428a8dFbF5B8Aa', 1],
        ['0x6267EA99990Dc8360D7Ce01ea364C0Ca4ef0b98b', 1],
        ['0x0047A47684D548cb6aa0121C1F2d4907eA85c3Ef', 1],
        ['0xd68E5655d84aB9a6eF7Af8fEffC162965BeE28e7', 1],
        ['0xb6c5E4bda1F2e5f1D65D0cF9532D04DCD21b8b69', 1],
        ['0xD9397d6303540CD9C9c3433e7D2575e04fDff81c', 1],
        ['0x942Fe66f147009696712c72e222E5cCa8492DDf8', 1],
        ['0xBD2A645382E633888573b3d95D4261e3724CFC53', 1],
        ['0xecF036262234e8F7d16B4fa81c31Ef1E881506a4', 1],
        ['0xF658816ea5b08B7445C4906E03123454079CB835', 1],
        ['0xe2E51949F112829A4395e8302cEb9887C345D1aC', 1],
        ['0x970b52Bf8964934E721f655325cc946e4901bE6b', 1],
        ['0x6Dcca3CF9A219c7BEBe5c198b683E69422Eff5FD', 1],
        ['0x8C84BA68b7E9Ad4D901E8254C521f1de7f5d2101', 1],
        ['0x10994c5775c2A825e15bc016fB03Dec068a650AC', 1],
        ['0x293C48a2dDB54c5aA7270Bffb4B8273696dd2B03', 1],
        ['0x30DEaC7DE44036bBE93F2270d11D06C0eE6FB3a2', 1],
        ['0x7f04c4387423c5460f0a797b79B7De2A4769567A', 1],
        ['0x7142B52a14ec98AEE5762E64Ae64F5B1deF1B60D', 1],
        ['0x48811BEAA39017F8C7A659C99CEF4442866A2855', 1],
        ['0xBaBa59f763AC5CA958612354f4dfAcdf6c5Cd56d', 1],
        ['0xc84c7472b2CBe01FE5FF1801232815b71E225dA8', 1],
        ['0x65622710a497C0a0593DcB4fabE4066ebDAADdFe', 1],
        ['0x6f4Ae03a55Edf3C647C74595A810D3BC3B2B42F2', 1],
        ['0x603605EE6BB1F996216B6B2a039711024e3d8d75', 1],
        ['0xfC46520B3975A5847919CfebD458c649cb90BAD6', 1],
        ['0x89F04F5C012eDa374E38a7012aeB3CB43c90A52f', 1],
        ['0x277274Bd0b677d5911a43ae692086cEDe4afc34b', 1],
        ['0x45b3b6F94a8c8c94c953d4a2109011EED8957A69', 1],
        ['0xF19Fd8746b85E63B513490eb993f0360eFc0f9f0', 1],
        ['0x49cec38718aE0aaE40F13fa8DD965fc0844E8056', 1],
        ['0xeF5b0272F846393798c70429085acF63aAEf4596', 1],
        ['0x20AEA0973D4652B32f98b7Fe391c583d33Bc818D', 1],
        ['0x4f1cF459303607b1AAC83B604364d6ed30f7578D', 1],
        ['0x541f7Be7f5eAB6e05c604Cc91f1CEB283753E614', 1],
        ['0x56A3f120A9B56164600aC75b8ceEaC4306127E52', 1],
        ['0xBB8d9884e0421549e15A042c978F75F048E4e832', 1],
        ['0x5367F038dfb7e45b522EB9b1f497d6cA3259E063', 1],
        ['0x2b1E2cCc15d4A72b57f6aa3F8869b95f7bFD62Ba', 1],
        ['0xB7D0213AD8b8270E54Aadac21e6680886444A14F', 1],
        ['0x34dB83Aa47DbEa2e5e65EFaaf963453c0B13e2e8', 1],
        ['0xB3270E06009F0C446C3B9a84fbcB729946D49828', 1],
        ['0x04DA399a998286cEBa2efeD7fA368F7b4D6c4841', 1],
        ['0x2ae736cd6b2aeF895C79126BF15473875115d2C2', 1],
        ['0x85D3370171858f8930D044025b55223778f65985', 1],
        ['0x4999b20E3f255BF5f18DE7af257C1e96bB3e2B4c', 1],
        ['0x4a5546DfD5f0aB8363072dB5fC98924198432E6e', 1],
        ['0x68Aa761c027B5d5f528f359101bC9EC31504a18C', 1],
        ['0x59f2d218c7b302831e3874Be1C678C94952e909c', 1],
        ['0x418Dc4484e28648EaF1374Dc6fd82c555cF369ee', 1],
        ['0x54f3fE6df2bfa10F1C15E3C22d7E03C69e81867D', 1],
        ['0xfb2908E87264b4804B48320c3d5b05be3D709288', 1],
        ['0x431D47eD942f1c3ad7bd8BA43C47A5afB50C657C', 1],
        ['0x43b21b41FBB344490E9991f1bdda4A75e3974332', 1],
        ['0x80c0088A56828BB0930bC28B93fcE0415B611d4e', 1],
        ['0xb7BE18AE9E8FE280018A13B5ba1b35F8B353E6e4', 1],
        ['0x36c1238af9cd4D640e6c5D4184Fc88A2117265F3', 1],
        ['0x0d909F81F143e5802A191e046ebe0B7721871193', 1],
        ['0x7CcD823888bD7d46f5a8c8A46dc09254c9DF5BC3', 1],
        ['0x0000Efa8dce86e986946528Bbe6d37cC58731d3F', 1],
        ['0xC65B70eb5B7bbb5Fb147469b84Ce4D4985A8cC1a', 1],
        ['0x769cA812a7b37B075A8B7418413E2e7eD57E258c', 1],
        ['0x496f1Fc9fA8Be75e10A1fd7364313A3F0bf8631c', 1],
        ['0x2ff02Bb928A87471EFF15764c68D935D1054EB3F', 1],
        ['0x2C58036197bedE1BfD0252aCE7008ad6fae85a4D', 1],
        ['0xB07A7FC8FCad7fd215d667bBBA182D8208C93eE0', 1],
        ['0x8b21A854C5a51680a0811E129a982D9663Ab0800', 1],
        ['0x01c239E4f21657041aa61dAE57412633eB12980f', 1],
        ['0x1B3398D4ef42cbC59cC1f6133511C761a4b15E37', 1],
        ['0x3a8C3AD6BB90FDBA219BeD592eA58774641ACA7f', 1],
        ['0x76cB463076756E197AB8d55106241Ccc19791829', 1],
        ['0x12BF113d5633536269Ea71CeD6a250e048dF7FE3', 1],
        ['0x3096b9129E7b865F15b3EA8CD2b39d2Ac032Bfd2', 1],
        ['0xa3F07d2057F479959ffDCd8e5D2E983442c8eA26', 1],
        ['0xF31809f1a39971b8E46e4a98E5E5edC3df2a2b94', 1],
        ['0xCCddaE671253e07141491aff3B86C7feBF6B4940', 1],
        ['0x4225f60f20Dadc440ED9ac58a92dA969925961E3', 1],
        ['0xb0E3BA3E883eFb6B9280Bb96E3315CD252b59206', 1],
        ['0x19bD5b353667EcF774a9fcd78A8d44B7aB9e3781', 1],
        ['0xA1FF024d56DBDe16dAe48abE70522ad2E22457dc', 1],
        ['0x25BDb613b7D599f39729c4924590e873711bA31c', 1],
        ['0x0A3FBdF0E96fbfa2270595b06d698b0aEF06c0b6', 1],
        ['0xE0BB6f58242d8018518D38CbeA6D19ef9dcE66d0', 1],
        ['0x6650793eC795eCA95D4d6C41E8b4C21e4efeb6DE', 1],
        ['0xcd8D30465f0F4cD610387477c9B1175f8Ed2642B', 1],
        ['0xB5DBCDcc467a4D5c9Ba1cF943F1670CB3e0A57E8', 1],
        ['0xaC1FE6824626eB0a0FEE40c8b002e3e11dFa66aC', 1],
        ['0x553936bFC38dc84F013612B3C5c8357d5777b371', 1],
        ['0x4BFe62262Bb818E14aC74AC144139330F497C4Ee', 1],
        ['0x64D40F447A8CA45b2beAD0210aa32B984e3535a9', 1],
        ['0x769d7d1A56F0bcC95Ac1604781CFB74C088776A6', 1],
        ['0x419044327EeB77dDBFB01aAcf381Fe29ed330ea9', 1],
        ['0x671123d5D99041348Efc4fe93a63F420D234AA44', 1],
        ['0x747010709A3f7D5484C0bD313f6d01AD12735480', 1],
        ['0xC8851c13Dd7dC1800CdecF90de50f381E8BBD72C', 1],
        ['0x81e8D9709cb423c41496A41F7b34Eb38B0131ad9', 1],
        ['0x507b6860B31d4E1fd854Ac48fcA85231296e5AaF', 1],
        ['0x34c16B0F74292d5D8363e7e083be058bc1C6F9C7', 1],
        ['0x9c2C3fB4c7F70e73E966FBa5eF8260934EC4a18F', 1],
        ['0xFBBF00F6c2a8B399728bC7E2Dd6eEE684C791745', 1],
        ['0xbfb97247ea28739c777dfb09300706776a5d3558', 1],
        ['0x360E3861886e755F4481f7A719d563B1d3e0Db14', 1],
        ['0x83C8A388652E4866780fB04cC6C413f576F6Dbb3', 1],
        ['0xa18F8DbCc92B7bdb0B0A397E36EBABf6333D86A7', 1],
        ['0x5029D2c51F77bb7Ee7e8183fAC46d49B760B8995', 1],
        ['0x0056014B7016A0944Ab677D6Faab0c3CEe5c1e4E', 1],
        ['0x11b7246173E16F8C10b07d04b719E48282EBe2Cd', 1],
        ['0x98ab92Bf864528fee9E362Ab81624356d556ba57', 1],
        ['0xfff5b39356dA493BDaf3602F48b20112708d7369', 1],
        ['0x0Ec468C00De7fa935455297252D586058f5CaB79', 1],
        ['0x773d95942878ce8f29E9B3B768d2180401820F95', 1],
        ['0xC8f6eaE3149275223Bf669c0EF6a53A79CCE0e27', 1],
        ['0x4948D9bDb807DBE0936c73EBF4f0D15Da67F93FB', 1],
        ['0xeFf01B77916DE61ECa5644E58C92E2bd869a6908', 1],
        ['0x4933Af8F30c621A8b722B72e8f546bb27981139C', 1],
        ['0x8A77D61F84035a542e2cA8955CAEB6e6F2418C1B', 1],
        ['0x74A49bc7704b68fC56d36ef560183eA622f2849d', 1],
        ['0x331ED56a7d78bB41E20c73E0c305fA81814AbD11', 1],
        ['0x64cbDb394D348001C752689601b4A072638A08eC', 1],
        ['0x1211f3748A7EeE3466d2611e92765e6F41A29CA8', 1],
        ['0x0a8ff77c0fc6e606Fbd4dC01F46D35Cb0d0fF1e8', 1],
        ['0x0f93dc1CEA29EF3AE38F3C49c748276B09Fb27DC', 1],
        ['0xeCF52C6672E8c6910363Ea77140FCB4B068E5A1a', 1],
        ['0x439BC62Fc92d3fCa10E77a0b1B388B5e38D5a1a4', 1],
        ['0x3CEBfEfbda10326A2a5B4aEb33A0FEffe2bA14D7', 1],
        ['0x99ADB31FF7A571Ee68C5f9A6f68E2144fab5d4F6', 1],
        ['0x0D02a91d96E236c580fab877f054c7707738b8c9', 1],
        ['0x893640f645C74FD813b17da915e5FDf1afBA7C46', 1],
        ['0x228A5802A57a6F9CDc9B2822f944125b28226aD9', 1],
        ['0x1815E41Fb4b6830e6D8963337ec8d0cB63Bd20D8', 1],
        ['0x1Cd78d64E9a938635a032aaCb941C89a12B14c65', 1],
        ['0x07F3AF0547c45390a44FC62B2D4BeA0670Fb6147', 1],
        ['0x23EF62f21d9971A172d4996edCB08aBD1CF24fF7', 1],
        ['0xA16138E563886ed2033B3399617FE10DcdF70743', 1],
        ['0x6de30431CB1dF5752F5fB5faC1F3F24097AC7f3F', 1],
        ['0xcbc1c9b2d69f24b36a927d3e6276bc627ea45fcf', 1],
        ['0x5E7B23bf050541e6fDBE3266E52A18c8A0Fb43E3', 1],
        ['0x926B9543CeE9f61aCa649d3259C600691391e48E', 1],
        ['0x91769843CEc84Adcf7A48DF9DBd9694A39f44b42', 1],
        ['0x91769843CEc84Adcf7A48DF9DBd9694A39f44b42', 2],
        ['0x02B31016FB9054D6Fb622d214a77e568F2d723C5', 1],
        ['0x02B31016FB9054D6Fb622d214a77e568F2d723C5', 2],
        ['0xeede87f7d1a79b014cd6c2bf7fc4567fd29afcba', 1],
        ['0xeede87f7d1a79b014cd6c2bf7fc4567fd29afcba', 2],
        ['0x92DC59566CC193211bCB43b1325a1f1d5BC10b96', 1],
        ['0xdf55165CDF45fB4fAd54fcd9f1D423721d05400D', 1],
        ['0x2B0f5b4A5295566A68FbDAaBFF0E327681AAaDff', 1],
        ['0xD2a6d9887D48296BA716E56C7F1039874d16fEc7', 1],
        ['0x9632D7AaF0288e937B664E04BE63973aA0940d6D', 1],
        ['0x0aEeE8fC1b67c093dB9798810f5A94aa7676FC3d', 1],
        ['0x3e64981c4aa1DCa444FD36B3C2A55eDAfD8116cf', 1],
        ['0xA49cCeac18444D9E5CeCd5098600f5E7E90Ce21e', 1],
        ['0xf1F9524F03757be667BC79784ec28B80Ff2694Fd', 1],
        ['0x72FEdEd900f3eC44AE183844D6eCDaF7917f32B1', 1],
        ['0xcD5b490388bF6840c96C94E38E03FF2F8d7E6a6E', 1],
        ['0xb31F229a0e88cC94C072b497Ea2F1047d39db304', 1],
        ['0x9305219f22bf290B2ea4f529AEb13604724F1E2B', 1],
        ['0xeB76D1B7e1F7103d22de1444bBe05675F36a4501', 1],
        ['0xd5575C4E4b59A9D0f26A1cC81Cd2Fc3D86BAF913', 1],
        ['0xCd883f9251C44CcD8419080c03BB1619CC1D56d6', 1],
        ['0xc8DB9D1e26B9487e29D31503e2256aa9AC8e94bF', 1],
        ['0x7D3b7D0b5CF722E6feCeA23e4aCcC5EAFDa41828', 1],
        ['0x786c0A45462501a4da10e7eBDdC07D113B67c192', 1],
        ['0xaae16b72de4Ef8085DA2547Cfc2ed586657142Ab', 1],
        ['0xd299EF7a1992Fa31a704a87465ffd538D9e5Cf76', 1],
        ['0x4c3bC653fA8c6C14296B2c3AF38491ec477cA180', 1],
        ['0xC5239E1248a7D9b6B4292136F577A7212dc6E749', 1],
        ['0x59faC31cFd5E266ca2c39346b33BEBdbC0CE9340', 1],
        ['0xfE3f3cEAb7266b5de5Ae8738727b6cf82F7Be76c', 1],
        ['0x470d3Cb1DCF59cC34d6e9281F6041751C6c3305a', 1]
      ],
      // phase2
      [
        ['0xd7900e1Fee30e70EfDd5D377a7cEC457Ea81142B', 1],
        ['0x8845938e23D338552fA58cdd1B599C8eab1bF597', 0],
        ['0x2CFe89E3BAa8845954FbD257Ad351e9f6570291a', 0],
        ['0x082Fc1776d44f69988C475958A0505A5BC2cd77b', 0],
        ['0x00000000236AA20a26dbdD359362f4D517E6138E', 0],
        ['0x00000000f0a3bF37085E13814053a92C063635FC', 0],
      ],
      // phase 3
      [
        ['0x91769843CEc84Adcf7A48DF9DBd9694A39f44b42', 0],
        ['0x8845938e23D338552fA58cdd1B599C8eab1bF597', 0],
        ['0x2CFe89E3BAa8845954FbD257Ad351e9f6570291a', 0],
        ['0x082Fc1776d44f69988C475958A0505A5BC2cd77b', 0],
        ['0x00000000236AA20a26dbdD359362f4D517E6138E', 0],
        ['0x00000000f0a3bF37085E13814053a92C063635FC', 0],
      ],
    ];
    this.merkleTrees = [];
    

    // Use the code below and the commented run() function at the bottom 
    // of the file when you want to run this script by itself as
    // "node utils/merkle.js" on the command line.

    // var fs = require('fs');
    // const readFile = (filePath) => {
    //   try {
    //     const data = fs.readFileSync(filePath, 'utf8');
    //     const addresses = data.split('\n');
    //     return addresses;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
    // let d = readFile('./utils/whitelists/0.csv');
    // this.whitelistAddresses[0] = d;
    // console.log('loaded file 0.csv');
    // d = readFile('./utils/whitelists/1.csv');
    // this.whitelistAddresses[1] = d;
    // console.log('loaded file 1.csv');
    // d = readFile('./utils/whitelists/2.csv');
    // this.whitelistAddresses[2] = d;
    // console.log('loaded file 2.csv');
    // console.log('Finished loading data from CSVs...');

    // console.log('Generating MerkleTrees...');
    this.merkleTrees[0] = this.generateMerkleTree(0);
    this.merkleTrees[1] = this.generateMerkleTree(1);
    this.merkleTrees[2] = this.generateMerkleTree(2);

    // console.log('Printing the trees...');
    // console.log('Tree 0\n', this.merkleTrees[0].toString());
    // console.log('Tree 1\n', this.merkleTrees[1].toString());
    // console.log('Tree 2\n', this.merkleTrees[2].toString());

    // console.log('\n================\n\n');
  }

  generateMerkleTree(phase) {
    const leaves = this.whitelistAddresses[phase].map((addr) =>
      soliditySha3(addr[0], addr[1]),
    );
    var tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    return tree;
  }

  getMerkleRoot(phase) {
    var tree = this.merkleTrees[phase];
    return tree.getRoot();
  }

  getProof(addr, qty, phase) {
    var tree = this.merkleTrees[phase];
    return tree.getHexProof(soliditySha3(addr, qty));
  }

  run() {
    console.log(
      'm.getMerkleRoot(0):\t',
      `0x${m.getMerkleRoot(0).toString('hex')}`,
    );
    console.log(
      'm.getMerkleRoot(1):\t',
      `0x${m.getMerkleRoot(1).toString('hex')}`,
    );
    console.log(
      'm.getMerkleRoot(2):\t',
      `0x${m.getMerkleRoot(2).toString('hex')}`,
    );

    // console.log(
    //   'm.getProof(0x082Fc1776d44f69988C475958A0505A5BC2cd77b):\n\t\t\t',
    //   JSON.stringify(
    //     m.getProof('0x082Fc1776d44f69988C475958A0505A5BC2cd77b', 0),
    //   ),
    // );
    // console.log(
    //   'm.getProof(0x00000000f0a3bF37085E13814053a92C063635FC):\n\t\t\t',
    //   JSON.stringify(
    //     m.getProof('0x00000000f0a3bF37085E13814053a92C063635FC', 0),
    //   ),
    // );
  }
}

const m = new Merkle();

// m.run();
// var proof = m.getProof('0x91769843CEc84Adcf7A48DF9DBd9694A39f44b42',2,0);
// console.log(proof);
// pass proof / qty into contract mint call

export default m;
