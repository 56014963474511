import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Mint from './Components/Pages/Mint'
import reportWebVitals from './reportWebVitals'
import { RecoilRoot } from 'recoil'

export default function Home() {
	return (
		<React.StrictMode>
			<RecoilRoot>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<App />} />
						<Route path="/mint" element={<Mint />} />
					</Routes>
				</BrowserRouter>
			</RecoilRoot>
		</React.StrictMode>
	)
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Home />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
