/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import img1 from "../images/about-img1.png";
import img2 from "../images/about-img2.png";

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <h2>About Smol Kongz Reborn</h2>
        <div className="about-wrapper">
          <div className="images">
            <img src={img1} alt="about" />
            <img src={img2} alt="about" />
          </div>
          <div className="content">
            <p>
              At Smol Kongz Reborn, we recognize the adversity we as a community
              have faced within the NFT Space. What helps us wake up the next
              day, is the chance to re-build and re-focus.
            </p>
            <p>
              As a growing community that includes creators, artists, traders,
              physical and mental health experts, we recognize that all of us
              can grow when we bring a diverse perspective to the table. By
              creating a holistic community of people who interact with one
              another, and not just for the promise of a reward, Smol Kongz
              Reborn focus is on creating a lasting community.
            </p>
            <p>
              In the future, your Smol Kongz Reborn will not only be your key to
              the community, but it will be evidence that you were a pioneer in
              the field. The success reaped by dedicated members of our
              community will continue to draw interest and growth as we continue
              to build.
            </p>
            <p>
              With a strong focus on community, we seek to become an inclusive
              gateway to all who are interested in learning how to navigate and
              build relationships in Web3. Many of us have experienced loss in
              this space and by linking with other like-minded individuals, we
              can help overwrite that narrative of failure that we have
              experienced, instead highlighting our ability to overcome
              adversity.
            </p>
            <p>
              Smol Kongz Reborn has always been about establishing community, when all
              seems lost. When experiencing chaos, having a safe place to start,
              rebuild, and grow, in what many might consider to be a chaotic
              space, is an invaluable resource. With a focus on transparency,
              the Smol Kongz Reborn team will provide clear and actionable goals to
              empower the community in it's knowledge and success.
            </p>
            <a className="discord-btn" href="https://www.discord.gg/smolkongz">Join the Kongmunity</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
